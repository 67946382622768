import type { RecursivePartial } from '#types/utils'
import type { PagesConfig } from '#types/config/pages'

export default {
  gender: {
    options: [
      { value: 'M', label: 'male' },
      { value: 'F', label: 'female' },
      { value: 'NA', label: 'preferNotToAnswer' }
    ],
    requiredLocales: [
      'de-at',
      'de-be',
      'de-ch',
      'de-de',
      'de-lu',
      'en-cz',
      'en-dk',
      'en-fi',
      'en-gb',
      'en-ie',
      'en-se',
      'es-es',
      'fr-be',
      'fr-ch',
      'fr-fr',
      'fr-lu',
      'it-ch',
      'it-it',
      'nl-be',
      'nl-nl',
      'pl-pl',
      'pt-pt',
    ]
  }
} satisfies RecursivePartial<PagesConfig['profile']>
