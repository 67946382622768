import type { AgreementCheckbox, SignUpConfig } from '#types/config/components/form/signUp'
import type { RecursivePartial } from '#types/utils'

export default {
  agreementCheckboxes: (() => ([
    {
      type: 'termsConditionsAndPolicy',
      locales: [
        'de-at',
        'de-be',
        'de-ch',
        'de-lu',
        'en-cz',
        'en-dk',
        'en-fi',
        'en-ie',
        'en-se',
        'fr-be',
        'fr-ch',
        'fr-lu',
        'it-ch',
        'nl-be',
        'pl-pl',
        'pt-pt',
      ],
      required: true
    },
    {
      type: 'loyaltyTerms',
      locales: ['de-de', 'en-gb', 'es-es', 'fr-fr', 'it-it', 'nl-nl'],
      required: true
    },
    {
      type: 'newsletter',
    },
  ] satisfies AgreementCheckbox[])) as any,
  gender: {
    options: [
      { value: 'M', label: 'male' },
      { value: 'F', label: 'female' },
      { value: 'NA', label: 'preferNotToAnswer' }
    ],
    requiredLocales: [
      'de-at',
      'de-be',
      'de-ch',
      'de-de',
      'de-lu',
      'en-cz',
      'en-dk',
      'en-fi',
      'en-gb',
      'en-ie',
      'en-se',
      'es-es',
      'fr-be',
      'fr-ch',
      'fr-fr',
      'fr-lu',
      'it-ch',
      'it-it',
      'nl-be',
      'nl-nl',
      'pl-pl',
      'pt-pt',
    ]
  },
  loyaltyAgreementCheckboxes: [
    {
      type: 'loyaltyTerms',
      locales: ['de-de', 'en-gb', 'es-es', 'fr-fr', 'it-it', 'nl-nl'],
      required: true
    }
  ],
  mobilePhone: {
    enableCountryCodeSelection: true
  }
} satisfies RecursivePartial<SignUpConfig>
