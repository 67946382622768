import apiBrandify from "/opt/build/repo/brands/vans/regions/emea/config/app/api/brandify"
import componentsCheckoutCartContainer from "/opt/build/repo/brands/vans/regions/emea/config/app/components/checkout/cartContainer"
import componentsCheckoutShippingAndDelivery from "/opt/build/repo/brands/vans/regions/emea/config/app/components/checkout/shippingAndDelivery"
import componentsCustomsHeader from "/opt/build/repo/brands/vans/regions/emea/config/app/components/customs/header"
import componentsDialogMiniCart from "/opt/build/repo/brands/vans/regions/emea/config/app/components/dialog/miniCart"
import componentsDialogSignUp from "/opt/build/repo/brands/vans/regions/emea/config/app/components/dialog/signUp"
import componentsFormInterests from "/opt/build/repo/brands/vans/regions/emea/config/app/components/form/interests"
import componentsFormSignUp from "/opt/build/repo/brands/vans/regions/emea/config/app/components/form/signUp"
import componentsKlarna from "/opt/build/repo/brands/vans/regions/emea/config/app/components/klarna"
import componentsProductUpsell from "/opt/build/repo/brands/vans/regions/emea/config/app/components/product/upsell"
import pagesHelp from "/opt/build/repo/brands/vans/regions/emea/config/app/pages/help"
import pagesPdp from "/opt/build/repo/brands/vans/regions/emea/config/app/pages/pdp"
import pagesProfile from "/opt/build/repo/brands/vans/regions/emea/config/app/pages/profile"
    
export default {"api":{"brandify":apiBrandify},"components":{"checkout":{"cartContainer":componentsCheckoutCartContainer,"shippingAndDelivery":componentsCheckoutShippingAndDelivery},"customs":{"header":componentsCustomsHeader},"dialog":{"miniCart":componentsDialogMiniCart,"signUp":componentsDialogSignUp},"form":{"interests":componentsFormInterests,"signUp":componentsFormSignUp},"klarna":componentsKlarna,"product":{"upsell":componentsProductUpsell}},"pages":{"help":pagesHelp,"pdp":pagesPdp,"profile":pagesProfile}}
