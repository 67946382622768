import type { RecursivePartial } from '#types/utils'
import type { PdpConfig } from '#types/config/pages/pdp'

export default {
  gallery: {
    presets: {
      src: {
        width: 652,
        height: 815
      }
    },
    videoFit: 'cover',
  }
} satisfies RecursivePartial<PdpConfig>
