import type { ApiConfig } from '#types/config/api'
import type { RecursivePartial } from '#types/utils'

const pickupPayload = {
  or: {
    off: {
      eq: 'TRUE'
    },
    out: {
      eq: 'TRUE'
    },
    aut: {
      eq: 'TRUE'
    }
  }
}

export default {
  pickupPayloads: {
    default: pickupPayload as Record<string, any>
  }
} satisfies RecursivePartial<ApiConfig['brandify']>
